<template>
    <div>
        <div class="row mb-2">
            <div class="col-lg-7">
                <label for="select-search">Ricerca</label>
                <fg-input
                        addon-left-icon="ti ti-search"
                        v-model="p_search"
                        :keyUpSearch="false"
                        @keyup.enter.native="filter"
                ></fg-input>
            </div>
            <div class="col-lg-5">
                <label for="select-search">Azioni</label>
                <div>
                <button class="btn btn-primary" @click="filter()">Filtra</button>
                <button class="btn btn-default" @click="noFilter()">Annulla Filtri</button>
                <button class="btn btn-success" @click="addRule()"> Nuovo Redirect</button>
                </div>
            </div>
        </div>

        <div class="card">
            <vue-good-table
                    ref="generic-table"
                    mode="remote"
                    styleClass="vgt-table striped"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    title="Lista Eventi"
                    :columns="columns"
                    :rows="rows"
                    totalRecords
                    :paginate="true"
                    :lineNumbers="false"
                    :pagination-options="{
                                enabled: true,
                                position: 'top',
                                nextLabel: 'Successivo',
                                prevLabel: 'Precedente',
                                rowsPerPageLabel: 'Righe per pagina',
                                dropdownAllowAll: false,
                                perPage: myPerPage,
                                perPageDropdown: myPerPageDropdown,
                                setCurrentPage: serverParams.page
                    }"
                    :sort-options="{ enabled: true, }"
                    :selectOptions="{
                          enabled: entity_delete,
                          selectOnCheckboxOnly: true,
                          selectionInfoClass: 'generic-class',
                          selectionText: 'righe selezionate',
                          clearSelectionText: 'deseleziona',
                    }"
                    :isLoading=myLoading
            >
                <div slot="emptystate" id="emptystate">
                    {{emptystate_msg}}
                </div>

                <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                   <button class="btn btn-danger" @click="deleteRule(props.index)"> <span class="fa fa-trash"></span> </button>
                </span>
                <span v-else-if="props.column.field == 'code'">
                   <select v-model="rows[props.index][props.column.field]"  @change="changeRow(props.index); saveRow(props.index);" >
                     <option value="301"> 301 </option>
                     <option value="302"> 302 </option>
                     <option value="410"> 410 </option>
                   </select>
                </span>
                <span v-else>
                   <input type="text" v-model="rows[props.index][props.column.field]" @change="changeRow(props.index)" @blur="saveRow(props.index)"/>
                </span>
                </template>
                <div slot="selected-row-actions">
                    <a v-on:click="deleteAll()" v-if="entity_delete">
                        <p-button type="primary">Cancella Selezionati</p-button>
                    </a>
                </div>
            </vue-good-table>  <!-- chiusura componente -->
        </div>
    </div>
</template>
<style scoped>
input[type='text']{
  width: 100%;
  border: 1px solid lightgray;
}
</style>
<script>
    import axios from 'axios';
        const tableColumns = ["Id", "Cognome", "Email"]; //mantenere per i campi tolowercase header tabella
    var tableData = [];
    var self = this;

    export default {
        data() {
            return {
                table1: {
                    title: "Elenco 404",
                    subTitle: "",
                    columns: [...tableColumns],
                    data: [...tableData],
                    route: "errore404",
                    classe: "utente-class",
                    messaggio: "",
                    see_msg: false
                },
                columns: [
                    {
                        label: 'Vecchia rotta',
                        field: 'old_url',
                        filterable: true,
                        sortable: false,
                        html: true
                    },
                    {
                        label: 'Nuova rotta',
                        field: 'new_url',
                        filterable: false,
                        sortable: false,
                        html: true,
                    },
                    {
                        label: 'Codice',
                        field: 'code',
                        filterable: true,
                        sortable: false,
                    },
                    {
                        label: 'AZIONI',
                        field: 'action',
                        filterable: false,
                        html: true,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                        sortable: false,
                    }
                ],
                entity_create: false,
                entity_delete: false,
                rows: [],
                totalRecords: 0,
                serverParams: {
                    columnFilters: {},
                    sort: {
                        field: 'id',
                        type: 'desc',
                    },
                    page: 1,
                    limit: 25
                },
                name: 'my-table',
                p_search: '',
                emptystate_msg: '',
                myPerPageDropdown: [10, 20, 30, 40, 50],
                myPerPage: 50,
                myCurrentPage: 1,
                myLoading: false,
                filtroDestinazione: 0,
                filtroDestinazioneInput: 0,
                filtroProvincia: 0,
                filtroProvinciaInput: 0,
                filtroStato: -1,
                filtroComune: [],
                filtroComuneOutput: null
            };
        },

        created: async function () {

            if (await this.$api.user.can(this.table1.route + "-view") == false) {
                this.$router.replace("/not-found");
                return;
            }

            this.entity_create = this.$api.user.can(this.table1.route + "-create");
            this.entity_delete = this.$api.user.can(this.table1.route + "-delete");

            this.updateResource(this.serverParams.page, this.serverParams.perPage);
        },

        mounted: function () {


        },

        provide() {
            return {parentValidator: this.$validator};
        },

        methods: {

            filter() {
                this.serverParams.page = 1;
                this.updateResource(this.serverParams.page, this.serverParams.perPage);
            },

            noFilter() {
                this.p_search = "";
                this.updateResource(this.serverParams.page, this.serverParams.perPage);
            },

            updateResource(page_start, per_page) {


                var self = this;

                self.rows = [];
                self.emptystate_msg = "";
                self.myLoading = true;

                /*
                    ATTENZIONE: L'ORDINAMENTO PER POTER FUNZIONARE NECESSITA DI CORRISPONDENZA ESATTA TRA NOME FIELD LATO CLIENT E NOME FIELD
                    LATO SERVER
                */
                var typeSort = '+';
                if (this.serverParams.sort.type == "desc")
                    typeSort = '-';

                var id_comune = 0;
                if (Array.isArray(this.filtroComuneOutput) && this.filtroComuneOutput[0])
                    id_comune = this.filtroComuneOutput[0].id;

                var url = this.$url + 'operations/error404';

                axios({
                    method: 'get',
                    url: url,
                    headers: this.$config.axiosHeaders(),
                    params: {
                        page: page_start,
                        limit: per_page,
                        orderBy: typeSort + this.serverParams.sort.field,
                        backend: true,
                        keywords: self.p_search,
                    }

                }).then(function (response) {
                    if (response.data.message == 'Unauthenticated.') {
                        self.$root.unlogged = true;
                        alert('Sessione Scaduta');
                        localStorage.removeItem('login_details');
                        location.reload();
                    }

                    self.totalRecords = response.data.meta.total;
                    if (self.totalRecords == 0)
                        self.emptystate_msg = "Nessun risultato trovato";

                    self.rows = [];
                    var obj = response.data.data;
                    for( let evento of obj){

                        self.rows.push({
                            id: evento.id,
                            old_url: evento.old_url,
                            new_url: evento.new_url,
                            code: evento.code,
                            changed: false
                        });
                    }
                    self.see_msg = true;
                    self.myLoading = false;

                }).catch(function (error) {
                    console.error(error);
                    self.$processError(error);
                });

                var x = document.getElementsByClassName("vgt-checkbox-col").checked = false;
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
                this.$setNavigationInfo(this.table1.route, this.serverParams.page, this.serverParams.perPage, this.serverParams.sort.field, this.serverParams.sort.type);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems(params.currentPage, params.currentPerPage);
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage});
                this.loadItems(params.currentPage, params.currentPerPage);
            },

            onSortChange(params) {
                this.updateParams({
                    sort: {
                        type: params[0].type,
                        field: params[0].field,
                    },
                });
                this.loadItems(params.currentPage, params.currentPerPage);
            },

            onSortChangeOld(params) {
                this.updateParams({
                    sort: {
                        type: params.sortType,
                        field: this.columns[params.columnIndex].field,
                    },
                });
                this.loadItems(params.currentPage, params.currentPerPage);
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems(params.currentPage, params.currentPerPage);
            },

            loadItems(page, per_page) {

                this.updateResource(page, per_page);
            },
            addRule: function(){

              //Non voglio che vengano create tante regole vuote.
              if( this.rows.length > 0 && this.rows[0].id == null &&
                  this.rows[0].old_url == "" &&
                  this.rows[0].new_url == "" ){
                    return;
              }

              this.rows.unshift({
                id: null,
                old_url: "",
                new_url: "",
                code: 301,
                changed: false
              });
            },
            deleteRule: function (index){
               if (confirm(`Confermare cancellazione della rotta ${this.rows[index].old_url} => ${this.rows[index].new_url}`)) {
                  var self = this;


if( this.rows[index].id == null ){
  this.rows.splice(index,1);
  return;
}

                    axios({
                        method: 'delete',
                        url: this.$url + 'operations/error404/rotta',
                        headers: this.$config.axiosHeaders(),
                        params: {
                            id: this.rows[index].id,
                        }
                    }).then(function (response) {


                        var color = 2;
                        var icon = "ti-check";

                        if (response.data.status == 'success') {
                           self.rows.splice(index,1);
                        } else {
                            color = 3;
                            icon = "ti-close";
                        }
                        self.$notifyVue('top', 'center', 'success', response.data.data.message, icon);
                    }).catch(function (error) {
                        self.$processError(error);
                        self.$notifyVue('top', 'center', 'success', error.response.data.message, icon);
                    });
               }
            },
            changeRow: function(index){
              this.rows[index].changed = true;
            },
            saveRow: function (index){
              if( this.rows[index].changed == false || (this.rows[index].id == null &&
                  this.rows[index].old_url == "" &&
                  this.rows[index].new_url == "" ) ){
                    return;
              }
              console.info("Salvataggio");
              var self = this;

              axios({
                        method: 'post',
                        url: this.$url + 'operations/error404/rotta',
                        headers: this.$config.axiosHeaders(),
                        params: this.rows[index],
                    }).then(function (response) {
                        self.rows[index].changed = false;
                         self.rows[index].id = response.data.data.id;
                        console.info(response);
                    }).catch(function (error) {
                       console.error(error);
                        self.$processError(error);
                        self.$notifyVue('top', 'center', 'success', response.data.message, icon);
                    });


            },
            deleteAll: function () {
                if (confirm('Confermare cancellazione degli elementi selezionati?')) {
                    var elenco = [];
                    var selected = this.$refs['generic-table'].selectedRows;
                    for (var i = 0; i < selected.length; i++) {
                        elenco.push(selected[i].id);
                    }
                    var self = this;


                    axios({
                        method: 'delete',
                        url: this.$url + 'operations/error404/rotta',
                        headers: this.$config.axiosHeaders(),
                        params: {
                            id: elenco,
                        }
                    }).then(function (response) {
                        var color = 2;
                        var icon = "ti-check";

                        if (response.data.status == 'success') {
                            location.reload();
                        } else {
                            color = 3;
                            icon = "ti-close";
                        }
                        self.$notifyVue('top', 'center', 'success', response.data.data.message, icon);
                    }).catch(function (error) {
                        self.$processError(error);
                        self.$notifyVue('top', 'center', 'success', response.data.message, icon);
                    });
                }
            },

            asyncFindString: function () {
                if (this.p_search.length >= 2) {
                    //servono almeno 3 caratteri per avviare la ricerca
                    self = this;
                    clearTimeout(self.myVarTimeout); //annullo attesa precedente
                    self.myVarTimeout = setTimeout(function () {
                        //alert("ciao");
                        self.filter();
                    }, 1000);
                } //controllo lunghezza stringa
                else if (this.p_search.length == 0)
                    this.updateResource();
            },

            addZero(i) {
                if (i < 10) {
                    i = "0" + i;
                }
                return i;
            }
        } // methods
    };
</script>
