var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editor-page page"},[(_vm.loading)?_c('loader',{attrs:{"show":_vm.item == null,"width":40,"height":40}}):_vm._e(),_c('top-bar',{attrs:{"title":'Editor Categorie ' + _vm.title,"loading":_vm.loading,"preview":'',"action":false,"actionIsValid":_vm.isValid},on:{"lang":(l) => {
        _vm.lang = l;
      }}}),_c('Tree',{ref:"tree",attrs:{"value":_vm.treeData,"ondragend":_vm.ondragend,"eachDroppable":_vm.eachDroppable,"triggerClass":'drag-handle'},on:{"drop":_vm.update},scopedSlots:_vm._u([{key:"default",fn:function({ node, index, path, tree }){return _c('div',{},[_c('div',{staticClass:"main-content"},[(_vm.canSave)?_c('div',{staticClass:"controls"},[_c('span',{staticClass:"drag-handle"},[_c('DragHandle')],1),(node.children.length > 0)?_c('button',{on:{"click":function($event){return tree.toggleFold(node, path)}}},[_c('level-switch',{attrs:{"open":!node.$folded}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"content"},[_c('strong',[_vm._v(_vm._s(node.id))]),_vm._v(" - "+_vm._s(node.contents[_vm.lang].title)+" ")]),(_vm.canSave)?_c('div',{staticClass:"actions"},[_vm._v(" - "),_c('button',{on:{"click":() => {
                node.edit = !node.edit;
                if (node.edit) {
                  _vm.$set(node, 'clone', JSON.parse(JSON.stringify(node)));
                } else {
                  delete node.clone;
                }
              }}},[_vm._v(" modifica ")]),_c('button',{on:{"click":function($event){return _vm.deleteCategory(node)}}},[_vm._v("elimina")])]):_vm._e()]),(node.edit)?_c('div',{staticClass:"edit-panel d-flex align-items-center"},[_c('button',{on:{"click":function($event){return _vm.pickImage(node.clone.cover)}}},[_c('img',{staticClass:"cover",attrs:{"src":node.clone.cover.url_thumb,"alt":node.clone.contents[_vm.lang].title}}),_c('br'),_c('small',[_vm._v(" fai click per cambiare l'immagine ")])]),_c('form',{staticClass:"form ml-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"titolo"}},[_vm._v("Titolo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(node.clone.contents[_vm.lang].title),expression:"node.clone.contents[lang].title"}],staticClass:"form-control",attrs:{"type":"text","id":"titolo"},domProps:{"value":(node.clone.contents[_vm.lang].title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(node.clone.contents[_vm.lang], "title", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"slug"}},[_vm._v("Slug")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(node.clone.contents[_vm.lang].slug),expression:"node.clone.contents[lang].slug"}],staticClass:"form-control",attrs:{"type":"text","id":"slug"},domProps:{"value":(node.clone.contents[_vm.lang].slug)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(node.clone.contents[_vm.lang], "slug", $event.target.value)}}})]),_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":() => {
                node.cover = Object.assign(
                  {},
                  JSON.parse(JSON.stringify(node.clone.cover))
                );
                node.contents = Object.assign(
                  {},
                  JSON.parse(JSON.stringify(node.clone.contents))
                );
                _vm.saveNode(node);
              }}},[_vm._v(" Salva ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":() => {
                delete node.clone;
                node.edit = false;
              }}},[_vm._v(" Annulla ")])])]):_vm._e()])}}])}),(_vm.canSave)?_c('div',{staticClass:"form form-section"},[_c('h5',[_vm._v(" Aggiungi categoria "),_c('lang-switcher',{staticClass:"ls",attrs:{"langs":_vm.$available_langs,"btnClass":'light'},model:{value:(_vm.newCatLang),callback:function ($$v) {_vm.newCatLang=$$v},expression:"newCatLang"}})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Titolo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCatInfo[_vm.newCatLang].title),expression:"newCatInfo[newCatLang].title"}],staticClass:"input form-control",attrs:{"type":"text"},domProps:{"value":(_vm.newCatInfo[_vm.newCatLang].title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.newCatInfo[_vm.newCatLang], "title", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Slug")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCatInfo[_vm.newCatLang].slug),expression:"newCatInfo[newCatLang].slug"}],staticClass:"input form-control",attrs:{"type":"text"},domProps:{"value":(_vm.newCatInfo[_vm.newCatLang].slug)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.newCatInfo[_vm.newCatLang], "slug", $event.target.value)}}})]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.addCategory()}}},[_vm._v(" aggiungi ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }