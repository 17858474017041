<template>
  <div>
    <div class="row">
      <div class="col-auto">
        <search-box v-model="searchQuery.searchTerm" />
      </div>
      <slot name="filters" v-bind:query="searchQuery"> </slot>
      <div class="col-auto">
        <!-- stato -->
        <statuses-box v-model="searchQuery.stato"></statuses-box>
      </div>
      <div class="col-auto">
        <label>Azioni</label>
        <div
          class="btn-toolbar mr-2"
          role="toolbar"
          aria-label="Bottoni azione"
        >
          <div class="btn-group mb-2 mr-2" role="group" aria-label="Azioni">
            <button class="btn btn-primary" @click="filter()">
              <i class="ti ti-filter"></i> Filtra
            </button>
            <button class="btn btn-default" @click="noFilter()">
              <i class="ti ti-arrow-circle-up"></i>
              Annulla Filtri
            </button>
          </div>
          <router-link
            tag="button"
            type="button"
            class="btn mb-2 btn-success"
            :to="{ name: this.actions.new.route, params: { id: 'new' } }"
          >
            <i class="ti ti-plus" /> {{ this.actions.new.title }}
          </router-link>
        </div>
      </div>
    </div>

    <div class="card">
      <vue-good-table
        ref="generic-table"
        mode="remote"
        styleClass="vgt-table striped"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        title="Lista proposte"
        :columns="columns"
        :rows="rows"
        totalRecords
        :paginate="true"
        :lineNumbers="false"
        :pagination-options="{
          enabled: true,
          position: 'top',
          nextLabel: 'Successivo',
          prevLabel: 'Precedente',
          rowsPerPageLabel: 'Righe per pagina',
          dropdownAllowAll: false,
          perPage: serverParams.pagination.currentPerPage,
          perPageDropdown: serverParams.myPerPageDropdown,
          setCurrentPage: serverParams.pagination.currentPage,
        }"
        :sort-options="{ enabled: true }"
        :selectOptions="{
          enabled: entity_delete,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'generic-class',
          selectionText: 'righe selezionate',
          clearSelectionText: 'deseleziona',
        }"
        :isLoading="isLoading"
      >
        <div slot="emptystate" id="emptystate" v-if="totalRecords == 0">
          <div class="vgt-center-align vgt-text-disabled">
            <span v-if="!isLoading">Nessun risultato disponibile</span>
          </div>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'action' && actions.edit ">
            <action-table
              :name="actions.edit.routeName"
              :path="actions.edit.path"
              :id="props.row.id"
              :draft="props.row.draft"
              :revision="props.row.revision"
            >
            </action-table>
          </span>
          <span v-else-if="props.column.field == 'status'">
                <div class="d-flex justify-content-center align-items-center" v-for="(lang, langIndex) in langs" :key="langIndex">
                 <vue-country-flag class="px-2" :country='lang =="en" ? "gb": lang' size='small'/>
                 <statuses-span
                  :contents="props.row.contents[lang].status"
                ></statuses-span>
                </div>
          </span>
          <span v-else-if="props.column.field == 'flag'">
            <flag-available-content-language
              :align="1"
              :contents="props.row.contents"
            ></flag-available-content-language>
          </span>
        </template>
        <div slot="selected-row-actions">
          <a v-on:click="deleteAll()" v-if="entity_delete">
            <button class="btn btn-primary">
              <i class="ti ti-close"></i> Cancella Selezionati
            </button>
          </a>
        </div>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SearchBox from "@/components/altrama/SearchBox.vue";
import StatusesBox from "@/components/altrama/StatusesBox.vue";
import StatusesSpan from "@/components/altrama/StatusesSpan";

export default {
  components: {
    SearchBox,
    StatusesSpan,
    StatusesBox,
  },
  props: ['options'],
  data() {
    return {
      actions: this.options.actions || {},
      columns: [...(this.options.columns || [{
            label: "ID",
            field: "id",
            type: "number",
            html: false,
            tdClass: "text-center",
            thClass: "text-center",
            sortable: true,
          },
          {
            label: "TITOLO",
            field: "title",
            filterable: true,
            sortable: false,
          },
          {
            label: "STATO",
            field: "status",
            filterable: false,
            html: true,
            tdClass: "text-center",
            thClass: "text-center",
            sortable: false,
          }]), {
            label: "AZIONI",
            field: "action",
            filterable: false,
            sortable: false,
            html: true,
            tdClass: "text-center",
            thClass: "text-center",
          }],
      entity_create: false,
      entity_delete: false,
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc",
        },
        pagination: {
          currentPage: 1,
          currentPerPage: 50,
        },
        myPerPageDropdown: [10, 20, 30, 40, 50],
      },

      isLoading: false,
      searchQuery: {},
      langs: ["it", "en"],
    };
  },
  created: async function () {
    if (await this.$api.user.can(this.options.capability + "-view") == false) {
      this.$router.replace("/not-found");
      return;
    }
    this.entity_create = await this.$api.user.can(this.options.capability + "-create");
    this.entity_delete = await this.$api.user.can(this.options.capability + "-delete");
  },

  mounted() {
    if (localStorage.getItem("news_searchQuery")) {
      // parametri di ricerca
      this.searchQuery = this.getSearchFilter();
    }
    if (localStorage.getItem("news_serverParams")) {
      // parametri di tabella
      this.serverParams = this.getParameterFilter();
    }


    this.options.mapper = this.options.mapper || ((place) => {
      return {
        id: place.id,
        original_id: place.original_id,
        title: (place.contents  ? place.contents.it.title: ''),
        contents: place.contents,
      };
    });

    this.updateResource();
  },

  methods: {
    filter() {
      console.log("filter()");

      this.serverParams.pagination.currentPage = 1;
      this.saveSearchFilter();
      this.updateResource();
    },

    noFilter() {
      console.log("noFilter()");

      localStorage.removeItem("news_searchQuery");
      localStorage.removeItem("news_serverParams");

      this.serverParams = {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc",
        },
        pagination: {
          currentPage: 1,
          currentPerPage: 50,
        },
        myPerPageDropdown: [10, 20, 30, 40, 50],
      };

      this.searchQuery = {
        searchTerm: "",
        comune: { id: null },
        provincia: -1,
        destinazione: { id: null },
        stato: -1,
      };

      const query = this.searchQuery;
      for (var key in query) {
        query[key] = -1;
      }
      query["searchTerm"] = "";

      this.updateResource();
    },

    saveParameterFilter() {
      localStorage.setItem(
        "news_searchQuery",
        JSON.stringify(this.searchQuery)
      );
      localStorage.setItem(
        "news_serverParams",
        JSON.stringify(this.serverParams)
      );
    },

    saveSearchFilter() {
      // salva parametri di ricerca
      localStorage.setItem(
        "news_searchQuery",
        JSON.stringify(this.searchQuery)
      );
    },

    getSearchFilter() {
      // prende parametri di ricerca
      return JSON.parse(localStorage.getItem("news_searchQuery"));
    },

    getParameterFilter() {
      // prende parametri di tabella
      return JSON.parse(localStorage.getItem("news_serverParams"));
    },

    onPerPageChange(params) {
      // Risultati per pagina
      console.log("onPageChange");
      console.log(params);
      this.serverParams.pagination = params;
      this.updateResource();
    },

    onPageChange(params) {
      // risultati nel numero di pagina della paginazione (Es: pag 2 di 5)
      console.log("onPageChange");
      console.log(params);
      this.serverParams.pagination = params;
      this.updateResource();
    },

    onSortChange(params) {
      // ordinamento risultati per campo
      console.log("onSortChange");
      console.log(params[0]);
      this.serverParams.sort = params[0];
      this.updateResource();
    },

    async updateResource() {
      var self = this;
      self.rows = [];
      self.isLoading = true;

      var typeSort = String("+");
      if (this.serverParams.sort.type == "desc") {
        typeSort = String("-");
      }
      this.saveParameterFilter();

      await axios({
        method: "GET",
        url: this.options.apiGetRoute,
        headers: this.$config.axiosHeaders(),
        params: {
          keywords: self.searchQuery.searchTerm,
          destinazione:
            typeof self.searchQuery.destinazione === "object"
              ? self.searchQuery.destinazione.id
              : self.searchQuery.destinazione,
          provincia:
            typeof self.searchQuery.provincia === "object"
              ? self.searchQuery.provincia.id
              : self.searchQuery.provincia,
          comune:
            typeof self.searchQuery.comune === "object"
              ? self.searchQuery.comune.id
              : self.searchQuery.comune,
          stato: self.searchQuery.stato,
          orderBy: typeSort + self.serverParams.sort.field,
          page: self.serverParams.pagination.currentPage,
          limit: self.serverParams.pagination.currentPerPage,
        },
      })
        .then(function (response) {
          self.totalRecords = response.data.meta.total;
          self.rows = [];
          var obj = response.data.data;

          for (var x = 0; x < obj.length; x++) {
            let articolo = obj[x];
            self.rows.push( self.options.mapper( articolo ) );
          }
        })
        .catch(function (error) {
          self.$processError(error);
        })
        .finally(() => (self.isLoading = false));
    },

    deleteAll() {
      if (confirm("Confermare cancellazione degli elementi selezionati?")) {
        var elenco = [];
        var selected = this.$refs["generic-table"].selectedRows;
        for (var i = 0; i < selected.length; i++) {
          elenco.push(selected[i].id);
        }
        var self = this;
        self.isLoading = true;

        axios({
          method: "DELETE",
          url: this.options.apiDeleteRoute + "/" + elenco,
          headers: this.$config.axiosHeaders(),
        })
          .then(function (response) {
            self.$notifyVue(
              "top",
              "center",
              "success",
              response.data.data.message,
              "ti-check"
            );
            self.updateResource();
          })
          .catch(function (error) {
            self.$processError(error);
          })
          .finally(() => (self.isLoading = false));
      }
    },
  },
};
</script>

<style scoped>
.lists ul,
li {
  margin-top: -10px;
  margin-bottom: -10px;
}

.small-flag{
  margin-left: -14px !important;
  margin-right: -14px !important;
  margin-top: -11px !important;
  margin-bottom: -14px !important;
}
</style>
